import { Grid, Typography, FormControl, FormHelperText, FilledInput } from "@mui/material"
import { CTAButton } from "@components/parts/Button"
import locale from '@utils/locale'
import { requestAuth } from "@models/company-code"
import { useState, useEffect } from "react"
import Cookie from "js-cookie"
import { useRouter } from "next/router"
import { globalActions, globalSelector } from "@state/globalState"
import * as logger from "@utils/logger"

const LogIn = () => {
    logger.debug("View Login")
    const [companyCode, setCompanyCode] = useState("")
    const [error, setError] = useState(false)
    const router = useRouter()
    const setLoading = globalActions.useSetLoading()
    const isLogin = globalSelector.useToken()
    const setToken = globalActions.useSetToken()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyCode(event.target.value)
    }

    const onClick = async () => {
        setLoading(true)
        try{
            Cookie.remove("companycode")
            const data = await requestAuth(companyCode)
            if("error" in data){
                throw new Error("Invalid authentication code")
            }
            // Cookieの期限はAPIと同じで1時間（ドメインアクセス時に検証するので、APIと同じでなくてもよいと思う）
            // ログイン処理では、Cookieのセットとステートのセット両方行う
            setToken({token: data.authorization, isLoading: false})
            Cookie.set("companycode", data.authorization, {expires: 1/24})
            router.push("/entry")
        }catch(e: unknown){
            if(e instanceof Error){
                setError(true)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        isLogin.token && router.push("/entry")
    }, [isLogin])

    return(
        <>
            {
                (!isLogin.token && !isLogin.isLoading) ? (
                    <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center" sx={{marginTop: "35vh"}}>
                        <Grid item>
                            <Typography component="p" sx={{fontSize: "1rem", color: "bodyText.main", fontWeight: "fontWeightBold", margin: 0}}>{locale.t(locale.keys.otsukaCorporationAuth.input.instruct)}</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl error={error} sx={{width: "300px"}} variant="filled">
                                <FilledInput hiddenLabel id="input_company_code" value={companyCode} placeholder={locale.t(locale.keys.otsukaCorporationAuth.input.code)} onChange={handleChange} />
                                {
                                    error ?
                                    <FormHelperText id="helper_company_code" sx={{marginInlineStart: "0px"}}>
                                        {locale.t(locale.keys.otsukaCorporationAuth.input.attention)}
                                    </FormHelperText>
                                    :
                                    <></>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <CTAButton onClick={() => onClick()} variant="next" sx={{width: "300px"}}>{locale.t(locale.keys.action.authenticate)}</CTAButton>
                        </Grid>
                    </Grid>
                ) : <></>
            }
        </>
    )
}

export default LogIn