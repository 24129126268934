import { Button } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { environment, TENANT } from '@utils/constants/environment';
import Link from 'next/link';

type Props = {
    variant: 'next' | 'back';
    children: string;
    sx?: SxProps<Theme> | undefined;
    onClick?: () => void;
};

const getButtonColor = (): 'secondary' | 'third' => {
    return environment.tenant === TENANT.OTSUKA ? 'secondary' : 'third';
};

export const CTAButton = (props: Props) => {
    const buttonColor = getButtonColor();
    const buttonSx = {
        backgroundColor: (theme: Theme) => theme.palette[buttonColor].main,
        '&:hover': {
            backgroundColor: (theme: Theme) => theme.palette[buttonColor].dark,
        },
        '&:active': {
            backgroundColor: (theme: Theme) => theme.palette[buttonColor].light,
        },
    };
    switch (props.variant) {
        case 'next':
            return (
                <Button onClick={props.onClick} variant="contained" sx={{ ...buttonSx, ...props.sx }}>
                    {props.children}
                </Button>
            );
        case 'back':
            return (
                <Button onClick={props.onClick} sx={{ backgroundColor: 'none', border: '1px solid #000000', ...props.sx, color: 'common.black' }}>
                    {props.children}
                </Button>
            );
        default:
            return <p>error</p>;
    }
};

export const LinkButton = (props: Props) => {
    switch (props.variant) {
        case 'next':
            return (
                <Link href="/entry">
                    <Button variant="contained" color="secondary" sx={{ ...props.sx }}>
                        {props.children}
                    </Button>
                </Link>
            );
        case 'back':
            return (
                <Link href="/login">
                    <Button sx={{ backgroundColor: 'none', border: '1px solid #000000', ...props.sx, color: 'common.black' }}>{props.children}</Button>
                </Link>
            );
        default:
            return <p>error</p>;
    }
};
